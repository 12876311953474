import React from "react";

const Footer = (props) => {

  return (
    <>
       <footer className="blog-footer">
        <p>&copy;  <a href="https://www.yourspacejourney.com/">Your Space Journey</a>&nbsp;&nbsp;|&nbsp;&nbsp;<strong><a href="tel:1-929-732-4825">(929)732-4825</a></strong>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="mailto:info@yourspacejourney.com">info@yourspacejourney.com</a></p>
        <p>
          <a href="#">Back to top</a>
        </p>
      </footer>
    </>
  );
};

export default Footer;
