import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from 'react-router-dom';
import Launches from "./Launches";
import Section from '../pages/Section';

const LaunchFeed = () => {
    const location = useLocation();
    // console.log("LaunchFeed location", location.pathname.includes("launches"));
    const [launchList, setLaunchList] = useState([]);
    const [loading, setLoading] = useState(false);
    // const openWeatherMapAPI = '41dcd9d8063f4abb3ee28c6c6fbc6354';
    // const launchAPIURL = "https://lldev.thespacedevs.com/2.0.0/launch/upcoming/?limit=10&offset=0&ordering=net"; //dev

    useEffect(() => {
        let launchAPIURL = "https://ll.thespacedevs.com/2.0.0/launch/upcoming/?limit=10&offset=0&ordering=net"; //prod
        // let launchAPIURL = "https://lldev.thespacedevs.com/2.0.0/launch/upcoming/?limit=10&offset=0&ordering=net"; //dev

        const getLaunchData = async (search) => {
            setLoading(true);
             console.log("LaunchFeed getLaunchData | Processing...");
            if (search.length > 0) {
                launchAPIURL += "&search=" + search;
            }
            const request = await fetch(launchAPIURL);
            const data = await request.json();
            // console.log("getLaunchData", data);
            return data;
        };

        // const getWeatherForLocation = async (latitude, longitude) => {
        //     var apiURL = "https://api.openweathermap.org/data/2.5/onecall?lat=" + latitude + "&lon=" + longitude + "&units=imperial" + "&APPID=" + openWeatherMapAPI;
        //     const request = await fetch(apiURL);
        //     const data = await request.json();
        //     return data;
        // };

        // async function getWeatherForLocation(latitude, longitude, launchDate) {
        //     console.log('launchDate', launchDate);
        //     // var apiURL = "https://api.openweathermap.org/data/2.5/onecall?lat=" + latitude + "&lon=" + longitude + "&units=imperial" + "&APPID=" + openWeatherMapAPI;
        //     var apiURL = "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/" + latitude + "," + longitude + "/" + launchDate + "/" + launchDate + "?key=8XGESW3ZWZZ24R67XNWYYZDF8"
        //     console.log('apiURL', apiURL);
        //     return [];
        //     const request = await fetch(apiURL);
        //     const data = await request.json();
        //     return data;
        // }

        // var date_diff_indays = function (date1, date2) {
        //     let dt1 = new Date(date1);
        //     let dt2 = new Date(date2);
        //     return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
        // }

        const callLoadLaunches = async () => {
            // var search = searchtermEL.value.trim();
            let search = "";
            let launchData = [];

            if (sessionStorage.getItem("launches")) {
                launchData = JSON.parse(sessionStorage.getItem("launches"));
                setLaunchList(launchData);
                setLoading(false);
                return;
            } else {
                launchData = await getLaunchData(search);
            }

            //let launchData = await getLaunchData(search);
            // console.log('callDataInCorder line 45');
            // console.log('launchData', launchData);
            // check if api returned forecast
            // if (launchData.length === 0) {
            //     // launchData.textContent = "No launch data available";
            //     setLaunchList("No data available");
            //     return;
            // }

            if (launchData.results !== undefined && launchData.results !== null) {
                // loop through next 10 launches in array
                var launchesArr = launchData.results;
                // Assign value to a key
                sessionStorage.setItem("launches", JSON.stringify(launchesArr));
                setLaunchList(launchesArr);
                setLoading(false);
            }
            else {
                setLaunchList("No upcoming launches available");
            }

        };

        callLoadLaunches();

    }, []);

    return (
        // launchContainerEL
        <>
            <Section>
                {location.pathname.includes("launches") && (
                    <>
                        <h3>Rocket Launch Schedule</h3>
                        <Launches launches={launchList} loading={loading} />
                    </>
                )}
                {!location.pathname.includes("launches") && (
                    <>
                        <NavLink to="/launches"><h3>Rocket Launch Schedule</h3></NavLink>
                        <Launches launches={launchList} loading={loading} />
                    </>
                )}
            </Section >
        </>
    )
}

export default LaunchFeed;