import React from "react";
import Section from "../pages/Section";
import './/image-gallery.css';
import axiom1 from '../images/ChuckFields_AxiomAx1_00001.jpg';
import axiom1Thumbnail from '../images/ChuckFields_AxiomAx1_00001_thumbnail.JPG';
import falconHeavyArabsat6a from '../images/ChuckFields_FalconHeavyArabsat6A_Nikon_00007.jpg';
import falconHeavyArabsat6aThumbnail from '../images/ChuckFields_FalconHeavyArabsat6A_Nikon_00007_thumbnail.JPG';
import spacexCRS27 from '../images/ChuckFields_SpaceX_CRS27.jpg';
import spacexCRS27Thumbnail from '../images/ChuckFields_SpaceX_CRS27_thumbnail.JPG';
import oneweb2 from '../images/ChuckFields_SpaceX_OneWeb2_01102023.jpg';
import oneweb2Thumbnail from '../images/ChuckFields_SpaceX_OneWeb2_01102023_thumbnail.JPG';
import oneweb2sky from '../images/ChuckFields_SpaceX_OneWeb2_01102023_Z7i_00001DSC_0770.jpg';
import oneweb2skyThumbnail from '../images/ChuckFields_SpaceX_OneWeb2_01102023_Z7i_00001DSC_0770_thumbnail.JPG';
import starliner from '../images/ChuckFields_Starliner_05192022.jpg';
import starlinerThumbnail from '../images/ChuckFields_Starliner_05192022_thumbnail.JPG';
import starlinerWide from '../images/ChuckFields_Starliner_05192022_Wide.jpg'
import starlinerWideThumbnail from '../images/ChuckFields_Starliner_05192022_Wide_thumbnail.JPG';
import oneweb3 from '../images/ChuckFields_SpaceX_OneWeb3_launch_03092023_00001DSC_0973.jpg';
import oneweb3Thumbnail from '../images/ChuckFields_SpaceX_OneWeb3_launch_03092023_00001DSC_0973_thumbnail.JPG';
import oneweb3BoosterLanding from '../images/ChuckFields_SpaceX_OneWeb3_Booster_03092023_00011DSC_1051Landing.jpg';
import oneweb3BoosterLandingThumbnail from '../images/ChuckFields_SpaceX_OneWeb3_Booster_03092023_00011DSC_1051Landing_thumbnail.JPG';
import oneweb3Booster from '../images/ChuckFields_SpaceX_OneWeb3_BoosterLanding_03092023_00002DSC_1021.jpg';
import oneweb3BoosterThumbnail from '../images/ChuckFields_SpaceX_OneWeb3_BoosterLanding_03092023_00002DSC_1021_thumbnail.JPG';
import starlinkGroup51 from '../images/ChuckFields_Starlink_Group5-1_12282022.jpg';
import starlinkGroup51Thumbnail from '../images/ChuckFields_Starlink_Group5-1_12282022_thumbnail.JPG';
import transporter3 from '../images/ChuckFields_Transporter3_bBrightened.jpg';
import transporter3Thumbnail from '../images/ChuckFields_Transporter3_bBrightened_thumbnail.JPG';
import transporter3Closeup from '../images/ChuckFields_Transporter3_closeup.JPG';
import transporter3CloseupThumbnail from '../images/ChuckFields_Transporter3_closeup_thumbnail.JPG';
import starlink418 from '../images/ChuckFields_Starlink_4-18_00001DSC_0026.jpg'
import starlink418Thumbnail from '../images/ChuckFields_Starlink_4-18_00001DSC_0026_thumbnail.JPG';
import starlink418InFlight from '../images/ChuckFields_Starlink4-18_05182022_00002.jpg';
import starlink418InFlightThumbnail from '../images/ChuckFields_Starlink4-18_05182022_00002_thumbnail.JPG';
import StarshipLiftoff11182023_D5600_DSC_0015_8_ChuckFields_thumbnail from '../images/starshipift2/StarshipLiftoff11182023_D5600_DSC_0015_8_ChuckFields_thumbnail.JPG'
import StarshipLiftoff11182023_D5600_DSC_0015_8_ChuckFields from '../images/starshipift2/StarshipLiftoff11182023_D5600_DSC_0015_8_ChuckFields.JPG'
import StarshipLiftoff11182023_D5600_DSC_0015_050_thumbnail from '../images/starshipift2/StarshipLiftoff11182023_D5600_DSC_0015_050_thumbnail.JPG'
import StarshipLiftoff11182023_D5600_DSC_0015_050 from '../images/starshipift2/StarshipLiftoff11182023_D5600_DSC_0015_050.JPG'
import StarshipLiftoff11182023_DSC_1691_049_thumbnail from '../images/starshipift2/StarshipLiftoff11182023_DSC_1691_049_thumbnail.JPG'
import StarshipLiftoff11182023_DSC_1691_049 from '../images/starshipift2/StarshipLiftoff11182023_DSC_1691_049.JPG'
import StarshipLiftoff11182023_DSC_1724_042_thumbnail from '../images/starshipift2/StarshipLiftoff11182023_DSC_1724_042_thumbnail.JPG'
import StarshipLiftoff11182023_DSC_1724_042 from '../images/starshipift2/StarshipLiftoff11182023_DSC_1724_042.JPG'
import StarshipLiftoff11182023_DSC_1735_048_thumbnail from '../images/starshipift2/StarshipLiftoff11182023_DSC_1735_048_thumbnail.JPG'
import StarshipLiftoff11182023_DSC_1735_048 from '../images/starshipift2/StarshipLiftoff11182023_DSC_1735_048.JPG'
import StarshipLiftoff11182023_DSC_1754_044_thumbnail from '../images/starshipift2/StarshipLiftoff11182023_DSC_1754_044_thumbnail.JPG'
import StarshipLiftoff11182023_DSC_1754_044 from '../images/starshipift2/StarshipLiftoff11182023_DSC_1754_044.JPG'

import ImageGallery from "react-image-gallery";

const Photos = () => {

    const images = [
        {
            original: StarshipLiftoff11182023_D5600_DSC_0015_8_ChuckFields,
            thumbnail: StarshipLiftoff11182023_D5600_DSC_0015_8_ChuckFields_thumbnail,
            description:"SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            originalAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            thumnbailAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
          },
          {
            original: StarshipLiftoff11182023_DSC_1691_049,
            thumbnail: StarshipLiftoff11182023_DSC_1691_049_thumbnail,
            description:"SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            originalAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            thumnbailAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
          },
          {
            original: StarshipLiftoff11182023_DSC_1724_042,
            thumbnail: StarshipLiftoff11182023_DSC_1724_042_thumbnail,
            description:"SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            originalAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            thumnbailAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
          },
          {
            original: StarshipLiftoff11182023_DSC_1735_048,
            thumbnail: StarshipLiftoff11182023_DSC_1735_048_thumbnail,
            description:"SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            originalAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            thumnbailAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
          },
          {
            original: StarshipLiftoff11182023_DSC_1754_044,
            thumbnail: StarshipLiftoff11182023_DSC_1754_044_thumbnail,
            description:"SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            originalAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            thumnbailAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
          },
          {
            original: StarshipLiftoff11182023_D5600_DSC_0015_050,
            thumbnail: StarshipLiftoff11182023_D5600_DSC_0015_050_thumbnail,
            description:"SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            originalAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
            thumnbailAlt: "SpaceX Starship IFT-2 - Nov 18 2023 by Chuck Fields",
          },
          {
            original: oneweb3BoosterLanding,
            thumbnail: oneweb3BoosterLandingThumbnail, 
            description:"SpaceX OneWeb 3 Booster Landing - March 2023",
            originalAlt: "SpaceX OneWeb 3 Booster Landing - March 2023",
            thumnbailAlt: "SpaceX OneWeb 3 Booster Landing - March 2023",
          },
          {
            original: oneweb3Booster,
            thumbnail: oneweb3BoosterThumbnail, 
            description:"SpaceX OneWeb 3 Booster  - March 2023",
            originalAlt: "SpaceX OneWeb 3 Booster  - March 2023",
            thumnbailAlt: "SpaceX OneWeb 3 Booster  - March 2023",
          },
          {
            original: oneweb3,
            thumbnail: oneweb3Thumbnail, 
            description:"SpaceX OneWeb 3  - March 2023",
            originalAlt: "SpaceX OneWeb 3  - March 2023",
            thumnbailAlt: "SpaceX OneWeb 3  - March 2023",
          },
          {
            original: spacexCRS27,
            thumbnail: spacexCRS27Thumbnail, 
            description:"SpaceX CRS-27 - March 2023",
            originalAlt: "SpaceX CRS-27 - March 2023",
            thumnbailAlt: "SpaceX CRS-27 - March 2023",
          },
          {
            original: oneweb2,
            thumbnail: oneweb2Thumbnail, 
            description:"SpaceX OneWeb 2 - Jan 2023",
            originalAlt: "SpaceX OneWeb 2 - Jan 2023",
            thumnbailAlt: "SpaceX OneWeb 2 - Jan 2023",
          },
          {
            original: oneweb2sky,
            thumbnail: oneweb2skyThumbnail, 
            description:"SpaceX OneWeb 2 - Jan 2023",
            originalAlt: "SpaceX OneWeb 2 - Jan 2023",
            thumnbailAlt: "SpaceX OneWeb 2 - Jan 2023",
          },
          {
            original: starlinkGroup51,
            thumbnail: starlinkGroup51Thumbnail, 
            description:"SpaceX Starlink Group 5-1 - Dec 2022",
            originalAlt: "SpaceX Starlink Group 5-1 - Dec 2022",
            thumnbailAlt: "SpaceX Starlink Group 5-1 - Dec 2022",
          },
          {
            original: starlinerWide,
            thumbnail: starlinerWideThumbnail, 
            description:"Starliner OFT May 2022",
            originalAlt: "Starliner OFT May 2022",
            thumnbailAlt: "Starliner OFT May 2022",
          },
          {
            original: starliner,
            thumbnail: starlinerThumbnail, 
            description:"Starliner OFT May 2022",
            originalAlt: "Starliner OFT May 2022",
            thumnbailAlt: "Starliner OFT May 2022",
          },

          {
            original: starlink418,
            thumbnail: starlink418Thumbnail, 
            description:"SpaceX Starlink Group 4-18 - May 2022",
            originalAlt: "SpaceX Starlink Group 4-18 - May 2022",
            thumnbailAlt: "SpaceX Starlink Group 4-18 - May 2022",
          },
          {
            original: starlink418InFlight,
            thumbnail: starlink418InFlightThumbnail, 
            description:"SpaceX Starlink Group 4-18 - May 2022",
            originalAlt: "SpaceX Starlink Group 4-18 - May 2022",
            thumnbailAlt: "SpaceX Starlink Group 4-18 - May 2022",
          },
          {
            original: axiom1,
            thumbnail: axiom1Thumbnail, 
            description:"SpaceX Axiom 1 - April 8 2022",
            originalAlt: "SpaceX Axiom 1 - April 8 2022",
            thumnbailAlt: "SpaceX Axiom 1 - April 8 2022",
          },

        {
          original: transporter3Closeup,
          thumbnail: transporter3CloseupThumbnail, 
          description:"SpaceX Transporter 3 - Jan 2022",
          originalAlt: "SpaceX Transporter 3 - Jan 2022",
          thumnbailAlt: "SpaceX Transporter 3 - Jan 2022",
        },
        {
            original: transporter3,
            thumbnail: transporter3Thumbnail, 
            description:"SpaceX Transporter 3 - Jan 2022",
            originalAlt: "SpaceX Transporter 3 - Jan 2022",
            thumnbailAlt: "SpaceX Transporter 3 - Jan 2022",
          },
          {
            original: falconHeavyArabsat6a,
            thumbnail: falconHeavyArabsat6aThumbnail, 
            description:"SpaceX Falcon Heavy - ArabSAT-6A - April 11 2019",
            originalAlt: "SpaceX Falcon Heavy - ArabSAT-6A - April 11 2019",
            thumnbailAlt: "SpaceX Falcon Heavy - ArabSAT-6A - April 11 2019",
          },

      ];
    return (
        <div className="app">
      <header>
        <div className="header-wrapper">
        <h2>Rocket Launch Photos</h2><p>&copy; Chuck Fields</p>
        </div>
      </header>
      <div className="image-gallery-wrapper">
        <ImageGallery items={images} />
      </div>
    </div>

    )
}

export default Photos;