import React from "react";
// import Feed from "../components/Feed";
import FeedBrief from "../components/FeedBrief";
import LaunchFeed from "../components/LaunchFeed";
import Welcome from "../components/Welcome";
import YouTubeFeed from "../components/YouTubeFeed";
import './Home.css';

function Home(props) {
    // console.log("Dashboard props", props);

    return (
        <>
            <Welcome />
            <div className='rowC'>
                <YouTubeFeed />
                <div>
                    <LaunchFeed />
                    {/* <FeedBrief /> */}
                </div>

            </div>
        </>
    );
}

export default Home;
