import React, { useState } from "react";
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 18px 10px;
  }

  @media (max-width: 1048px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 6.5rem;
    transition: transform 0.3s ease-in-out;

    li {
      color: #fff;
    }
  }
`;

// This styling will be applied to a <NavLink> when the
// route that it links to is currently selected.
let activeStyle = {
  color: "blue",
  textDecoration: "none",
  padding: "0.5rem 1rem",
  background: "white",
};

let inactiveStyle = {
  color: "#FFFFFF",
  textDecoration: "none",
  padding: "0.5rem 1rem",
};

const RightNav = ({ open }) => {
  // console.log("RightNav");
  const [click, setClick] = useState(false);
  // const handleClick = () => setClick(!click);
  const closeMobileMenu = () => {
    setClick(false);
    Ul.open = !Ul.open;
  } 
  // const [toggle, setToggle] = useState(true);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Ul open={open}   >
          <li ><NavLink to="/" style={({ isActive }) =>
            isActive ? activeStyle : inactiveStyle
          } className='nav-item' onClick={closeMobileMenu}>Home</NavLink></li>
            <li><NavLink to="/podcast" style={({ isActive }) =>
            isActive ? activeStyle : inactiveStyle
          } className='nav-item' onClick={closeMobileMenu}>Podcast</NavLink></li>
          <li><NavLink to="/videos" style={({ isActive }) =>
            isActive ? activeStyle : inactiveStyle
          } className='nav-item' onClick={closeMobileMenu}>Videos</NavLink></li>
                    <li><NavLink to="/photos" style={({ isActive }) =>
            isActive ? activeStyle : inactiveStyle
          } className='nav-item' onClick={closeMobileMenu}>Gallery</NavLink></li>
                     <li><NavLink to="/posts" style={({ isActive }) =>
            isActive ? activeStyle : inactiveStyle
          } className='nav-item' onClick={closeMobileMenu}>Blog</NavLink></li>
          <li><NavLink to="/launches" style={({ isActive }) =>
            isActive ? activeStyle : inactiveStyle
          } className='nav-item' onClick={closeMobileMenu}>Upcoming Rocket Launches</NavLink></li>
        </Ul>
        <div className="social-right">
          <SocialIcon url="https://www.youtube.com/c/YourSpaceJourney" target="_blank" />
          <SocialIcon url="https://podcasts.apple.com/us/podcast/your-space-journey/id1477757732"  target="_blank"/>
          <SocialIcon url="https://open.spotify.com/show/7oG4OHn3WZFOTeNlqNEKTp?si=a5195150b6854b79"  target="_blank"/>
          <SocialIcon url="https://www.x.com/SpaceJourneyNow"  target="_blank"/>
          <SocialIcon url="https://www.instagram.com/yourspacejourney"  target="_blank"/>
          <SocialIcon url="https://www.facebook.com/yourspacejourney"  target="_blank"/>
        </div>
      </div>
    </>
  )
}

export default RightNav