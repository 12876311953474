import React, { useState } from "react";
import { useLocation } from 'react-router-dom';
// import LoadingSpinner from '../UI/LoadingSpinner';

const Launches = ({ launches, loading }) => {
    const location = useLocation();
    // console.log("Launches location", location.pathname.includes("launches"));

    // if (loading) {
    //     // console.log("loading...")
    //     return <h2>Loading...</h2>
    // }

    function date_diff_indays(date1, date2) {
        const dt1 = new Date(date1);
        const dt2 = new Date(date2);
        return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
    };

    function getUVIndexColorClass(uvIndex) {
        if (uvIndex < 3) {
            return "favorable";
        }
        else if (uvIndex < 8) {
            return "moderate";
        }
        else {
            return "severe";
        }
    }

    const ConvertDate = (launchDate) => {
        var localDate = new Date(launchDate).toLocaleString("en-US", {
            localeMatcher: "best fit",
            timeZoneName: "short"
        });

        return localDate;
    }

    return (
        <>
            {/* <div class="col-md-8 px-0"> */}
            {loading && (
                // <LoadingSpinner />
                <h6>Please try again later</h6>
            )}
            {!loading && launches && (
                // <>
                //    <h3>{posts.length}</h3>
                //    <h3>{posts[0].title[1]}</h3>
                //    </>
                launches.map((launch) => (
                    <div className="card flex-row" key={launch.name}>
                        <div className="card-body">
                            <h4 className="card-title h5 h4-sm">{ConvertDate(launch.net)}</h4>
                            <h4 className="card-title">{launch.name}</h4>
                            <div className="mb-2 h5 ">Status: {launch.status.name}</div>
                            <img className={location.pathname.includes("launches") ? "img-fluid" : "card-img-top example-card-img-responsive card-img"} style={{ width: "100%" }} title={launch.name} alt={launch.name} src={launch.image} />
                            {launch.probability && (
                                <div className="mb-2 text-muted">Probability: {launch.probability}%</div>
                            )}
                            {launch.holdreason && (
                                <div className="mb-2 text-muted">Hold Reason: {launch.holdreason}</div>
                            )}
                             {location.pathname.includes("launches") && (
                            <div className="mb-2 text-muted">
                                <ul>
                                    <li>Window ends: <strong>{ConvertDate(launch.window_end)}</strong></li>
                                    {/* {launch.mission.orbit && (
                                        <li>Trajectory: <strong>{launch.mission.orbit.name}</strong></li>
                                    )} */}
                                    {/* <li>Mission Type: <strong>{launch.mission.type}</strong></li> */}
                                </ul>
                            </div>
                             )}

                            {launch.mission && (
                                <p className="card-text" style={{ display: 'inline-block' }}>{launch.mission.description}</p>
                            )}
                            {location.pathname.includes("launches") ? (
                                <div className="mb-2 text-muted">
                                    <h5>Pad Info</h5>
                                    <ul>
                                        <li><strong>{launch.pad.name}</strong></li>
                                        <li>{launch.pad.location.name}</li>
                                        <li><a href={launch.pad.map_url} target="_blank"><img className="img-fluid" alt={launch.pad.name} title={launch.pad.name} src={launch.pad.map_image} /></a></li>
                                    </ul>

                                </div>) : (
                                <>
                                    <div><strong>{launch.pad.name}</strong></div>
                                    <div>{launch.pad.location.name}</div>
                                </>
                            )}
                        </div>
                    </div>
                ))
            )}
        </>
    )
};

export default Launches;