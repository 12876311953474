import React, { useState, useEffect } from "react";
import axios from "axios";
import YouTubeVideos from "./YouTubeVideos";
import PaginationYouTube from "./PaginationYouTube";
import LoadingSpinner from "../UI/LoadingSpinner";
import './YouTube.css';

const YouTubeFeed = () => {
    const [YouTubeList, setYouTubeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(3);
    const YouTubeAPIURL = "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails&playlistId=PLVkSQAp2ZP-dYQ-ByYQrg1sHLySywLR2D&key=AIzaSyBmSDd_a-Z4n_dMUuGGdBQMkJ7qN7md5xs&maxResults=50";

    useEffect(() => {
        const loadYouTubeVideos = async () => {
            let list = [];
            setLoading(true);
            const res = await axios
                .get(YouTubeAPIURL, {
                    "Content-Type": "application/xml; charset=utf-8"
                })

            res.data.items.forEach((video, index) => {

                if (!video.snippet.title.includes('video')) {
                    // console.log('video', video);
                    list.push(video);
                }
            });

            // console.log('list', list);
            setYouTubeList(list);
            setLoading(false);
        };

        loadYouTubeVideos();
    }, []);

    //get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = YouTubeList.slice(indexOfFirstPost, indexOfLastPost);

    //change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    if (loading) {
        return <LoadingSpinner header="Loading..." subheader="Please stand by" />;
    } else {
        return (
            <>

                <div className="videosection">
                    <h2>Space Exploration Interviews</h2>
                    <p>Visit us on YouTube at <a href="https://youtube.com/@YourSpaceJourney">youtube.com/@YourSpaceJourney</a> </p>
                    <YouTubeVideos YouTubeList={currentPosts} loading={loading} />
                    <PaginationYouTube postsPerPage={postsPerPage} totalPosts={YouTubeList.length} paginate={paginate} />
                </div>
            </>
        )
    }


}

export default YouTubeFeed;