import React from "react";
import LoadingSpinner from "../UI/LoadingSpinner";
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import Linkify from 'react-linkify';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
var parse = require('html-react-parser');

const YouTubeVideos = ({ YouTubeList, loading }) => {

    function replaceWithBr(str) {
        return str.replace(/\n/g, "<br />")
    }

    const ConvertDate = (pubDate) => {
        var localDate = new Date(pubDate).toLocaleString("en-US", {
            localeMatcher: "best fit",
            timeZoneName: "short"
        });

        return localDate;
    }

    if (loading) {
        // console.log("loading...")
        return <LoadingSpinner header="Loading..." subheader="Please stand by" />
    }

    return (
        <>
            {!loading && YouTubeList && (
                YouTubeList.map((video) => (
                    <div key={video.snippet.title} >
                        <h4>{video.snippet.title}</h4>
                        <div className="mb-2 text-muted">{new Date(video.contentDetails.videoPublishedAt).toLocaleDateString()}</div>
                        <LiteYouTubeEmbed
                            id={video.snippet.resourceId.videoId}
                            title={video.snippet.title}
                            poster="maxresdefault"
                            activeClass="lyt-activated" // Default as "lyt-activated", gives control to wrapper once clicked
                            iframeClass="" // Default none, gives control to add a class to iframe element itself
                            playerClass="lty-playbtn" // Default as "lty-playbtn" to control player button styles
                            wrapperClass="yt-lite" // Default as "yt-lite" for the div wrapping the area, the most important class and needs extra attention, please refer to LiteYouTubeEmbed.css for a reference.
                        />
                        <div className="mb-2 text-muted"><Linkify>{parse(replaceWithBr(video.snippet.description))}</Linkify></div>
                        <hr />
                    </div>
                ))
            )}
        </>
    )
};

export default YouTubeVideos;