import React, { useState, useEffect } from "react";
import axios from "axios";
import Posts from "./Posts";
import Pagination from "./Pagination";
import X2JS from "x2js";
import './Feed.css';

const spaceRssFeed = "https://anchor.fm/s/e638e1cc/podcast/rss";

const Feed = () => {

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);

  useEffect(() => {
    const loadPosts = async () => {
      let list = [];
      setLoading(true);
      const res = await axios
        .get(spaceRssFeed, {
          "Content-Type": "application/xml; charset=utf-8"
        })

      var x2js = new X2JS();
      var document = x2js.xml2js(res.data);
      // console.log('document', document);
      // console.log('document', document.rss.channel.item[0].title[1].toString());
      list = document.rss.channel.item;
      // console.log('list', list);
      setPosts(list);
      setLoading(false);
    };

    loadPosts();
  }, []);

  //get current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  //change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <>
      <div className="podcastsection">
        <h3>Space Podcast</h3>
        <Posts posts={currentPosts} loading={loading} brief={false} />
        <Pagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate} />
      </div>
    </>
  );

};

export default Feed;