import React from "react";

const Welcome = (props) => {
  return (
    <div className="container p-4 p-md-2  rounded text-bg-light">
      <div className='rowC'>
        <div className="text">
          <h1 className="display-4 fst-italic">Explore the Final Frontier</h1>
          <p className="lead my-3">Join us for "Your Space Journey," one of the best podcasts about space! This interview series takes you on an exhilarating voyage through the vast realm of space exploration.
            In this captivating series, we bring you in-depth conversations with the leaders of space exploration, including astronauts who have ventured beyond Earth, brilliant scientists who unravel the mysteries of the cosmos, and ingenious engineers who design cutting-edge spacecraft. </p>
        </div>
        <div>&nbsp;&nbsp;</div>
        <div>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/sW5Tmphjgvs" title="YouTube video player" border="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
      </div>
    </div>

  );
};

export default Welcome;
