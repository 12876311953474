// Post1.js

import { Card } from "react-bootstrap";
import starshipift2 from '../../images/starshipift2/StarshipLiftoff11182023_D5600_DSC_0016_9-min.JPG';

const Post1 = () => {
	return (
		<Card>
			<Card.Img
				variant="top"
				src={starshipift2}
                alt="Photo by Chuck Fields for Your Space Journey @2023"
			/>
            <Card.Subtitle className="mb-2 text-muted">Photo by Chuck Fields for Your Space Journey @2023</Card.Subtitle>
			<Card.Body>
				<Card.Title>Starship's Second Flight Test
                </Card.Title>
                <Card.Subtitle className="mb-2 text-muted">A successful failure</Card.Subtitle>
				<Card.Text>
                <p>SpaceX's Starship program is developing a fully reusable launch vehicle and spacecraft system to transport humans and cargo to Mars. The second Starship test flight took place on November 18, 2023, from Starbase in Texas.</p>

                <p><h5>Flight Objectives</h5>
                The primary objectives of the second test flight were to:
                <li>Demonstrate a full-duration ascent</li>
                <li>Perform a hot-stage separation with Starship and Booster</li>
                <li>Collect valuable data on the performance of Starship and its engines</li></p>

                <p><h5>Flight Highlights</h5>
                The flight was a significant milestone for the Starship program, as it achieved several important objectives:
                <li>Liftoff: The Super Heavy Booster, the first stage of Starship, successfully ignited all 33 of its Raptor engines and lifted off cleanly from the launch pad, thanks to the new water-cooled flame deflector and pad upgrades.</li>
                <li>Ascent: Starship and booster completed a successful hot-stage separation and flip maneuver, successfully lighting all six second stage Raptor engines, the first time this technique has been done with a vehicle of this size.</li>
                <li>Altitude: Starship reached an altitude of approximately 150 kilometers with a velocity of about 24,000 km/hour.</li>
                <li>Data Collection: The flight provided SpaceX with valuable data on the performance of Starship and its engines.</li></p>

                <p><h5>Rapid Unscheduled Disassembly (RUD)</h5>
                Despite the successful ascent, the flight ended in a "rapid unscheduled disassembly" (RUD), an explosion of the Starship Booster and upper stage. This was the second RUD for Starship, following the first test flight in April 2023.</p>

                <p><h5>Investigation and Next Steps</h5>
                SpaceX is currently investigating the cause of the RUD and is working on making changes to the vehicle to prevent future failures. SpaceX is also continuing to develop Starship, with the goal of conducting a crewed test flight to orbit in the next few years.

                The second flight of SpaceX Starship was a mixed bag, with both successes and concerns. However, the successes far outweigh the concerns, and SpaceX remains committed to the Starship program. I believe that SpaceX will overcome the challenges they face and achieve their goal of making Starship the first fully reusable launch vehicle capable of carrying humans to Mars.
                </p>

				</Card.Text>
                <Card.Link href="https://www.spacex.com/launches/mission/?missionId=starship-flight-2">Starship's Second Flight Test (SpaceX)</Card.Link>
                <Card.Link href="https://youtu.be/MD4dnrNmj2U">Starship Launch Photos from 6 miles, 1.5 miles, and a few hundred feet - and epic sound!</Card.Link>
				{/* <a href="#" className="btn btn-primary">Read More</a> */}
			</Card.Body>
		</Card>
	);
};

export default Post1;
