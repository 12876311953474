import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import MainNavigation from "./MainNavigation";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Layout = (props) => {
  const [state, setState] = useState("Active");

  return (
    <>
      <MainNavigation />
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
