import React from "react";
import "./spinner.css";

export default function LoadingSpinner(props) {
  return (
    <div className="spinner-container">
        <h2>{props.header}</h2>
        <p>{props.subheader}</p>
      <div className="loading-spinner">
      </div>
    </div>
  );
}