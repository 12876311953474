import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Feed from './components/Feed';
import LaunchFeed from './components/LaunchFeed';
import YouTubeFeed from './components/YouTubeFeed';
import Layout from "./components/layout/Layout";
import ErrorPage from "./pages/Error";
import Home from "./pages/Home";
import Footer from "./components/layout/Footer";
import Photos from "./components/Photos";
import Posts from "./components/blog/Posts";

function App() {
  // const [isLoading, setLoading] = useState(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Layout />
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: (
            <Home />
          ),
        },
        {
          path: "/videos",
          element: (
            <YouTubeFeed />
          ),
        },
        {
          path: "/podcast",
          element: (
            <Feed />
          ),
        },
        {
          path: "/launches",
          element: (
            <LaunchFeed />
          ),
        },
        {
          path: "/photos",
          element: (
            <Photos />
          ),
        },
        {
          path: "/home",
          element: (
            <Home />
          ),
        },
        {
          path: "/posts",
          element: (
            <Posts />
          ),
        },
      ],
    },

  ]);

  return (
    <>
      <RouterProvider router={router} />
      <Footer />
    </>
  );
}

export default App;
