import React from "react";
// import { parseDate } from "../util";
import parse from 'html-react-parser';

const Posts = ({ posts, loading, brief }) => {
    // console.log('brief', brief);
    if (loading) {
        // console.log("loading...")
        return <h2>Loading...</h2>
    }

    return (
        <>
        <h4>Details</h4>
            {!loading && posts && (
                posts.map((post) => (
                    <>
                    <hr></hr>
                        <div className="flex-row card" >
                           
                            <div className="card-body" >
                            {post.image && !brief && (<img className="card-img" alt={parse(post.title)} rel="preload" as="image" src={post.image._href} fetchpriority="high"/>)}
                                <h4 className="card-title h5 h4-sm">{parse(post.title)}</h4>
                                {Object.prototype.toString.call(post.pubDate) === "[object Date]" && (
                                    <div className="mb-2 text-muted">{new Date(post.pubDate).toLocaleDateString()}</div>
                                )}
                                {post.season && (
                                    <div className="mb-2 text-muted">Season {post.season.__text} Episode {post.episode.__text} </div>
                                )}
                                {!brief && (
                                    <p className="card-text">{parse(post.description)}</p>
                                )}
                                <div>
                                    {/* <a href={post.enclosure._url}>MP3</a> */}
                                    <audio controls>
                                        <source src={post.enclosure._url} type="audio/mpeg" controls preload="metadata" />
                                        <p>
                                            Download <a href={post.enclosure._url}>MP3</a> audio.
                                        </p>
                                    </audio>
                                </div>

                            </div>
                        </div>
                        <hr />
                    </>
                ))
            )}
        </>
    )
};

export default Posts;